import { registerBlockType } from "@wordpress/blocks";
import {
	MediaUpload,
	RichText,
	InspectorControls,
} from "@wordpress/block-editor";
import { Button, PanelBody } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { useState } from "@wordpress/element";

import {
	useBlockProps,
	useInnerBlocksProps,
	__experimentalGetElementClassName,
} from "@wordpress/block-editor";

import "./style.scss";

const BlockComponentEdit = ({ attributes, setAttributes }) => {
	const { items, expanded } = attributes;
	const [popupItem, setPopupItem] = useState(false);

	const addItem = () => {
		const newItems = [...items, { image: "", text: "" }];
		setAttributes({ items: newItems });
	};

	const updateItem = (index, newData) => {
		console.log(newData);

		const newItems = [...items];
		newItems[index] = newData;
		setAttributes({ items: newItems });
	};

	const removeItem = (index) => {
		const newItems = [...items];
		newItems.splice(index, 1);
		setAttributes({ items: newItems });
	};

	const showPopup = (index) => {
		setPopupItem(index);
	};

	const hidePopup = () => {
		setPopupItem(false);
	};

	const decodeEntities = (encodedString) => {
		const parser = new DOMParser();
		// console.log(encodedString);
		const decodedString = parser.parseFromString(encodedString, "text/html").body
			.textContent;
		return decodedString;
	};

	const blockProps = useBlockProps();
	return (
		<div className="byvet-component ">
			<InspectorControls>
				{items.map((item, index) => (
					<div key={`component_${index}`}>
						<PanelBody title={`Składnik - ${index + 1}`}>
							<MediaUpload
								onSelect={(media) =>
									updateItem(index, { ...item, image: media.url })
								}
								render={({ open }) => (
									<div onClick={open} style={{ cursor: "pointer" }}>
										{item.image ? (
											<img
												src={item.image}
												alt=""
												style={{
													width: "100%",
													height: "auto",
													maxWidth: "100px",
												}}
											/>
										) : (
											__("Add Image", "moj-plugin")
										)}
									</div>
								)}
							/>
							<RichText
								style={{ marginTop: "30px" }}
								tagName="p"
								value={item.name}
								format="html"
								formatTypes={["core/bold", "core/italic", "html"]}
								onChange={(name) => updateItem(index, { ...item, name })}
								placeholder={__("Nazwa składniku", "moj-plugin")}
							/>
							<RichText
								style={{ marginTop: "30px" }}
								tagName="p"
								value={item.shortDescription}
								formatTypes={["core/bold", "core/italic", "my-custom-html"]}
								onChange={(shortDescription) =>
									updateItem(index, { ...item, shortDescription })
								}
								placeholder={__("Krótki opis", "moj-plugin")}
							/>
							<RichText
								style={{ marginTop: "30px" }}
								// tagName="div"
								// multiline="p"
								value={item.longDescription}
								formatTypes={["core/bold", "core/italic", "my-custom-html"]}
								onChange={(longDescription) =>
									updateItem(index, { ...item, longDescription })
								}
								placeholder={__("Długi opis", "moj-plugin")}
							/>
							<Button isDefault onClick={() => removeItem(index)}>
								{__("usuń", "moj-plugin")}
							</Button>
						</PanelBody>
					</div>
				))}
				<PanelBody>
					<Button isPrimary onClick={addItem}>
						{__("Dodaj", "moj-plugin")}
					</Button>
				</PanelBody>
			</InspectorControls>
			<div className="by__component">
				{items.map((item, index) => {
					// console.log(decodeEntities(item.shortDescription));
					return (
						<div
							data-component={`component_${index}`}
							className="by__component__item"
							key={`component_reder_${index}`}
							onClick={() => showPopup(index)}
						>
							<div className="by__component__img">
								{item.image ? <img src={item.image} alt="" /> : ""}
							</div>
							<div className="by__component__description">
								<h4
									dangerouslySetInnerHTML={{
										__html: item.name,
									}}
								/>
								<p
									dangerouslySetInnerHTML={{
										__html: item.shortDescription,
									}}
								></p>
								<button className="by__component__icon">
									<svg
										width="12"
										height="12"
										viewBox="0 0 12 12"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M6 1V11M11 6H1"
											stroke="#1F1C75"
											stroke-linecap="round"
										/>
									</svg>
								</button>
							</div>
						</div>
					);
				})}
			</div>
			{items.map((item, index) => {
				console.log(item);
				return (
					<div
						data-component={`component_${index}`}
						className={`popup ${popupItem === index ? "active" : ""}`}
						key={`component_popup_${index}`}
					>
						<div className="popup__overlay" onClick={() => hidePopup()}></div>
						<div className="popup__wrapper">
							<div className="popup__img">
								{item.image ? <img src={item.image} alt="" /> : ""}
							</div>
							<div className="popup__content">
								<div className="popup__exit" onClick={() => hidePopup()}>
									<svg
										width="19"
										height="20"
										viewBox="0 0 19 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M18.5 0.999996L0.500004 19M18.5 19L0.500004 0.999996"
											stroke="#1F1C75"
											stroke-linecap="round"
										/>
									</svg>
								</div>
								{item.name && (
									<h3
										dangerouslySetInnerHTML={{
											__html: decodeEntities(item.name),
										}}
									/>
								)}
								{item.longDescription && (
									<p
										dangerouslySetInnerHTML={{
											__html: decodeEntities(item.longDescription),
										}}
									/>
								)}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

const BlockComponentSave = ({ attributes }) => {
	const { items, expanded } = attributes;
	console.log(RichText.Content);
	const decodeEntities = (encodedString) => {
		const parser = new DOMParser();
		// console.log(encodedString);
		const decodedString = parser.parseFromString(encodedString, "text/html").body
			.textContent;
		return decodedString;
	};

	return (
		<>
			<div className="by__component">
				{items.map((item, index) => (
					<div
						data-component={`component_${index}`}
						className="by__component__item"
						key={`component_${index}`}
					>
						<div className="by__component__img">
							{item.image ? <img src={item.image} alt="" /> : ""}
						</div>
						<div className="by__component__description">
							<h4
								dangerouslySetInnerHTML={{
									__html: item.name,
								}}
							/>
							<p
								dangerouslySetInnerHTML={{
									__html: item.shortDescription,
								}}
							/>
							<button className="by__component__icon">
								<svg
									width="12"
									height="12"
									viewBox="0 0 12 12"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M6 1V11M11 6H1"
										stroke="#1F1C75"
										stroke-linecap="round"
									/>
								</svg>
							</button>
						</div>
					</div>
				))}
			</div>
			{items.map((item, index) => (
				<div
					className={`popup ${expanded === index ? "open" : ""}`}
					key={`component_popup_${index}`}
					data-component={`component_${index}`}
				>
					<div className="popup__overlay"></div>
					<div className="popup__wrapper">
						<div className="popup__img">
							{item.image ? <img src={item.image} alt="" /> : ""}
						</div>
						<div className="popup__content">
							<div className="popup__exit" onClick={() => hidePopup()}>
								<svg
									width="19"
									height="20"
									viewBox="0 0 19 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M18.5 0.999996L0.500004 19M18.5 19L0.500004 0.999996"
										stroke="#1F1C75"
										stroke-linecap="round"
									/>
								</svg>
							</div>
							{item.name && (
								<h3
									dangerouslySetInnerHTML={{
										__html: item.name,
									}}
								/>
							)}
							{item.longDescription && (
								<p
									dangerouslySetInnerHTML={{
										__html: item.longDescription,
									}}
								/>
							)}
						</div>
					</div>
				</div>
			))}
		</>
	);
};



(() => {
	const componentesList = document.querySelectorAll(".by__component__item");
	const exitPopup = document.querySelectorAll(".popup__overlay");
	const exitOverlay = document.querySelectorAll(".popup__exit");
	if (componentesList.length) {
		componentesList.forEach((item) => {
			// console.log(item);
			item.addEventListener("click", function (e) {
				e.preventDefault();
				showPopup(item);
			});
		});
	}
	if (exitPopup.length) {
		exitPopup.forEach((item) => {
			item.addEventListener("click", function (e) {
				e.preventDefault();
				hidePopup();
			});
		});
	}
	if (exitOverlay.length) {
		exitOverlay.forEach((item) => {
			item.addEventListener("click", function (e) {
				e.preventDefault();
				hidePopup();
			});
		});
	}
	function showPopup(element) {
		const id = element.dataset.component;
		const body = document.querySelector("body");
		const wrapper = element.closest("section");
		body.style.overflow = "hidden";
		wrapper.style.zIndex = "9999";
		// console.log(id);
		const popup = document.querySelector('.popup[data-component="' + id + '"]');
		popup.classList.add("active");
		// console.log(popup);
	}
	function hidePopup() {
		const activePoup = document.querySelector(".popup.active");
		const body = document.querySelector("body");
		// const wrapper = element.closest('section');
		if (activePoup) {
			activePoup.classList.remove("active");
			body.style.overflowY = "initial";
			// wrapper.style.zIndex = "10";
		}
	}
})();

registerBlockType("moj-plugin/block-component", {
	title: "ByVet Block Component",
	icon: "admin-appearance",
	category: "By vet",
	attributes: {
		items: {
			type: "array",
			default: [
				// name:{}
				// shortDescription:{}
				// longDescription:{}
			],
		},
	},
	edit: BlockComponentEdit,
	save: BlockComponentSave,
});
